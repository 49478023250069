<template>
  <div class="flex column center" style="margin-bottom:4rem;">
    <div class="new_box">
      <div class="titlee">中国药科大学狄斌教授一行莅临历思就“污水验毒”等技术进行研讨交流！</div>
      <p class="time">2020.11.13</p>

      <p class="space_content" style="text-indent:0">
        近日，中国药科大学狄斌教授、苏梦翔副教授等一行莅临福建历思司法鉴定所（以下简称“历思”）进行研讨交流。
      </p>
      <p class="space_content" style="text-indent:0">
        座谈会上，双方围绕毒品检测技术和仪器设备的科学化、现代化、标准化等问题进行交流沟通，并就历思的污水采样装备和创新技术进行深刻地交流。狄斌教授对历思在污水验毒领域上的研究深度和技术水平进行了肯定，同时对历思的毛发检测、毒情溯源技术、新精神药物鉴定等问题进行深入地探讨。
      </p>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/33-1.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/33-2.png')">
      </div>
      <div class="col8" style="margin:2rem auto">
        <img class="col8" v-lazy="require('../../assets/news/ls/33-3.png')">
      </div>
      <p class="space_content" style="text-indent:0">
        狄斌教授一行参观了历思物证中心实验室，详细了解了历思创新设备的检测鉴定过程，对历思的实验室建设、技术创新、先进设备、人才梯队培养等方面给予高度赞赏。双方一致认为，应进一步加强沟通交流，探讨可以开展合作的领域。
      </p>

    </div>
    <div class="flex wrap .col442" style="width:100%;text-align:left;margin-top:1rem">
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex" style="margin-right:10%">
          <p style="white-space:nowrap">上一篇：</p>
          <router-link class="a" :to="'/news/lsnews/32'"><p class="ellipsis1">【攻略】条条线路通历思~</p>
          </router-link>
        </div>
      </div>
      <div style="margin-bottom:1rem" class="col4 flex  start1">
        <div class="flex">
          <p style="white-space:nowrap">下一篇：</p>
          <router-link class="a" :to="'/news/lsnews/34'"><p class="ellipsis1">【官宣】“污水验毒”专用智慧采样器，硬核上线啦~</p>
          </router-link>

        </div>
      </div>
      <div class="col2 flex  end1 center">
        <router-link class="a" style="color:#fff" :to="'/news/lsnews'">
          <el-button type="primary">返回列表</el-button>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      data: {}
    }
  },
}
</script>
<style>
.redText {
  background: red;
  color: white;
  font-size: 20px;
  font-weight: 600;
}

.titleText {
  font-size: 20px;
  font-weight: 600;
}
</style>
